require("bootstrap")

console.log("cookie.js LOADED")

function init() {
		if (document.cookie.indexOf("CookieAccept=true") < 0) {
			$("#staticBackdrop").modal('show')
			//Modal has been shown, now set a cookie so it never comes back
			$(".cookie-accept-button").click(function () {
				document.cookie = "CookieAccept=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/";
			});
		}
}

$(document).on("turbolinks:load", function() {
	$(function () {
		init()
	})
});
